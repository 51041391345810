import React from "react"
import BusinessAPI from "../../../components/body/pages/en-ng/business/businessAPI"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const BusinessAPIPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/open-api/"}
      title="Kuda Open API | Connect your Business with Business API | Kuda Business"
      description="Unlock a world of possibilities with Kuda's open API. Seamlessly integrate innovative banking solutions into your applications."
    />

    <BusinessAPI />
  </Layout>
)

export default BusinessAPIPage
